import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {
  FaPencilRuler,
  FaProjectDiagram,
  FaAward,
  FaLaptopCode,
  FaGraduationCap,
  FaUniversity,
  FaSchool,
} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TimelineSection from "../components/timelineSection"

import Resume from "../docs/Rishav_Resume.pdf"

import useJsonData from "../hooks/use-jsonData"
import useImages from "../hooks/use-images"

import { SecMainHead } from "../styles/styledComponent"

// make a new icon list mapping it with original value
const iconMapList = [
  {
    name: "FaLaptopCode",
    value: FaLaptopCode,
  },
  {
    name: "FaGraduationCap",
    value: FaGraduationCap,
  },
  {
    name: "FaUniversity",
    value: FaUniversity,
  },
  {
    name: "FaSchool",
    value: FaSchool,
  },
]

// obtain the original icon component as per given name
const obtainActualIcon = iconName => {
  return iconMapList.filter(item => item.name === iconName)
}

const AboutPage = () => {
  const jsonData = useJsonData()
  const images = useImages()

  let academicDetail = jsonData.academicDetail
    .sort((a, b) => {
      return b.data.uniqueId - a.data.uniqueId
    })
    .map(item => {
      let tempData = { ...item.data }
      tempData.Icon = obtainActualIcon(tempData.icon)[0].value
      return tempData
    })

  let workExperience = jsonData.workExperience
    .sort((a, b) => {
      return b.data.uniqueId - a.data.uniqueId
    })
    .map(item => {
      let tempData = { ...item.data }
      tempData.Icon = obtainActualIcon(tempData.icon)[0].value
      return tempData
    })
  return (
    <Layout>
      <SEO
        title="Rishav Pandey | About Me"
        description="Rishav Pandey Portfolio"
      />
      <div className="about-page">
        <section className="sec-type-1">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>My Introduction</SecMainHead>
              </div>
              <div className="sec-desc">
                <p>
                  Hey Peeps! I am Rishav, a resident of Medininagar, Jharkhand
                  currently in the final year of Engineering, specializing in
                  CSE. Owing to my education, my inclination has been towards
                  software development, mainly Full Stack Web Development,
                  Native Application Development, and Competitive Programming.
                  My belief is in maximizing the spreading of knowledge, which
                  has led me in contributing to many open source projects. I've
                  also a keen interest in psychology and the working of human
                  brain. Other than that, I can be found Mentoring, Teaching,
                  Giving Motivational Speeches, Reading Self Improvement books.
                  Exploring nature, Travelling, Learning new things, Writing
                  blogs, and Spending time with my family are my leisure
                  interests.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-type-2">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>My Work Experience</SecMainHead>
              </div>
              <div className="sec-desc">
                <div className="timeline-sec">
                  {workExperience.map(item => (
                    <TimelineSection item={item} key={item.uniqueId} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-type-1">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>My Educational Qualification</SecMainHead>
              </div>
              <div className="sec-desc">
                <div className="timeline-sec">
                  {academicDetail.map(item => (
                    <TimelineSection item={item} key={item.uniqueId} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-type-2">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>My Skills</SecMainHead>
              </div>
              <div className="sec-desc">
                <div className="grid-view md-2-col-grid">
                  <div className="intro-main-div">
                    <div className="image">
                      <Img
                        fluid={images.my_image.sharp.fluid}
                        alt="Rishav Pandey"
                        fadeIn={true}
                      />
                    </div>
                    <div className="desc">
                      <div className="title" style={{ marginTop: "2rem" }}>
                        <h3>Who's this guy?</h3>
                      </div>
                      <div className="intro">
                        <p>
                          I'm a self-taught computer programmer, currently
                          working on full-stack development of web applications
                          and native applications. I like building great things
                          from scratch. I have got a serious passion for
                          learning and adding new skills to my list every day.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="skills-wrapper">
                    {jsonData.skills.map((skill, i) => (
                      <div className="skill" key={i}>
                        <div className="name">
                          <span>{skill.data.name}</span>
                        </div>
                        <div className="range"></div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="connect-div grid-view md-2-col-grid">
                  <div className="connect-me">
                    <Link to="/contact" className="link-btn">
                      Get in touch with me
                    </Link>
                  </div>
                  <div className="resume">
                    <a
                      href={Resume}
                      target="_black"
                      rel="noopener noreferrer"
                      className="link-btn inverted-link-btn"
                    >
                      My Resume
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tech-summary-wrapper">
          <div className="container">
            <div className="grid-view md-3-col-grid">
              <div className="card">
                <div className="icons">
                  <FaPencilRuler className="fa-3x" />
                </div>
                <div>
                  <span>40+</span>
                </div>
                <div>
                  <span>MOOC's</span>
                </div>
              </div>
              <div className="card">
                <div className="icons">
                  <FaProjectDiagram className="fa-3x" />
                </div>
                <div>
                  <span>10+</span>
                </div>
                <div>
                  <span>Projects</span>
                </div>
              </div>
              <div className="card">
                <div className="icons">
                  <FaAward className="fa-3x" />
                </div>
                <div>
                  <span>5+</span>
                </div>
                <div>
                  <span>Awards & Honour</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AboutPage
