import React from "react"

const TimelineSection = props => (
  <div className="timeline-div-wrapper">
    <div className="timeline-icon-wrapper">
      <div>
        <props.item.Icon className="timeline-icon fa-icon" />
      </div>
    </div>
    <div className="timeline-heading-wrapper">
      <div className="title">
        <h3>{props.item.name}</h3>
      </div>
      <div className="desc">
        <p>
          {props.item.from} {props.item.to ? "-" : ""} {props.item.to}
        </p>
        <p>
          <em>{props.item.location}</em>
        </p>
      </div>
    </div>
    <div className="timeline-desc-wrapper">
      <div className="title">
        <h3>{props.item.institutionName}</h3>
      </div>
      <div className="desc">
        <p>{props.item.desc}</p>
        {props.item.techStack ? (
          <span>
            <h4 className="sub-head">My Tech stack includes:-</h4>
            <ul>
              {props.item.techStack.map(tech => (
                <li key={tech}>{tech}</li>
              ))}
            </ul>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
)

export default TimelineSection
