import { useStaticQuery, graphql } from "gatsby"

const useImages = () => {
  const { home_sec_bg_image, my_image } = useStaticQuery(graphql`
    query {
      home_sec_bg_image: file(relativePath: { eq: "main_sec_bg.jpg" }) {
        sharp: childImageSharp {
          fluid(grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      my_image: file(relativePath: { eq: "my_image.jpeg" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return {
    home_sec_bg_image,
    my_image,
  }
}

export default useImages
