import { useStaticQuery, graphql } from "gatsby"

const useJsonData = () => {
  const {
    academicDetail,
    projects,
    skills,
    workExperience,
  } = useStaticQuery(graphql`
    query {
      academicDetail: allAcademicDetailJson {
        edges {
          data: node {
            uniqueId
            icon
            name
            from
            to
            institutionName
            desc
          }
        }
      }
      projects: allProjectsJson {
        edges {
          data: node {
            uniqueId
            name
            shortDesc
            thumbnail {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            desc
            technologyUsed
            collaborators {
              name
              website
            }
            githubRepo
            url
            images {
              value {
                sharp: childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
            tags
            display
            status
          }
        }
      }
      skills: allSkillsJson {
        edges {
          data: node {
            name
          }
        }
      }
      workExperience: allWorkExperienceDetailJson {
        edges {
          data: node {
            uniqueId
            icon
            name
            from
            to
            type
            location
            institutionName
            desc
            techStack
          }
        }
      }
    }
  `)
  return {
    academicDetail: [...academicDetail.edges],
    projects: [...projects.edges],
    skills: [...skills.edges],
    workExperience: [...workExperience.edges],
  }
}

export default useJsonData
